import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import routes from './routes'
import './constants'
import './styles/antd.css'
import './styles/index.css'

const app = (
  <React.StrictMode>
    <BrowserRouter>{routes}</BrowserRouter>
  </React.StrictMode>
)

ReactDOM.render(app, document.getElementById('root'))
