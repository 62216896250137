import React, { Fragment, useRef, useState, useEffect } from 'react'
import moment from 'moment'
// import { Row, Col } from 'antd'
// import { Facebook, Instagram } from 'react-feather'

import { Banner } from '../components/Banner'
import { About } from '../components/About'
import { sections } from '../constants'
import { Contact } from '../components/Contact'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header/Header'
import { Mission } from '../components/Mission'
import { Team } from '../components/Team'
import { Donate } from '../components/Donate'
import { Partners } from '../components/Partners'
import { Announcement } from '../components/Announcement'

export const App = () => {
  const [showAnnouncement, setShowAnnouncement] = useState(false)
  const aboutRef = useRef(null)
  const missionRef = useRef(null)
  const contactRef = useRef(null)
  const teamRef = useRef(null)
  const donateRef = useRef(null)
  const partnersRef = useRef(null)
  const storiesRef = useRef(null)

  useEffect(() => {
    const startDate = moment('2022-05-01')
    const endDate = moment('2022-10-01')

    setShowAnnouncement(moment().isBetween(startDate, endDate, 'days'))
  }, [])

  const scrollTo = (section) => {
    switch (section) {
      case sections.ABOUT:
        aboutRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break
      case sections.MISSION:
        missionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break
      case sections.CONTACT:
        contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break
      case sections.TEAM:
        teamRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break
      case sections.DONATE:
        donateRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break
      case sections.PARTNERS:
        partnersRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break
      case sections.STORIES:
        storiesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break
      default:
        break
    }
  }

  return (
    <Fragment>
      {showAnnouncement && <Announcement />}
      <Header scrollTo={scrollTo} />
      <Banner />
      <About aboutRef={aboutRef} />
      <Mission missionRef={missionRef} />
      <Contact contactRef={contactRef} />
      <Team teamRef={teamRef} />
      <Partners partnersRef={partnersRef} />
      <Donate donateRef={donateRef} />
      {/* <div className='section-light-thin'>
              <Row  align='middle' justify='center' gutter={32}>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <h5>Extra info or social media links</h5>
                </Col>
                <Col>
                  <a
                    className='btn-header-link'
                    href='https://cru.ucalgary.ca/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Facebook />
                  </a>
                </Col>
                <Col>
                  <a
                    className='btn-header-link'
                    href='https://cru.ucalgary.ca/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Instagram />
                  </a>
                </Col>
              </Row>
            </div> */}
      <Footer />
    </Fragment>
  )
}
