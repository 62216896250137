import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { App } from './pages/App'
import { NotFound } from './pages/NotFound'

export default (
  <Routes>
    <Route exact path='/' element={<App />} />
    <Route path='*' element={<NotFound />} />
  </Routes>
)
