import React from 'react'
import { Col, Row } from 'antd/lib/grid'

export const Banner = () => {
  return (
    <div className='section-dark-left'>
      <Row align='middle' className='align-middle container'>
        <Col xl={12} lg={12} md={24} className='section-light-left-content'>
          <Row align='middle' gutter={[16, 16]}>
            <Col xl={8} lg={6} md={6} sm={6} xs={6}>
              <picture>
                <source srcSet={'/images/partners/asf-logo.webp'} type='image/webp' />
                <img
                  src={'/images/partners/asf-logo.png'}
                  className='logo-img'
                  alt='asf'
                />
              </picture>
            </Col>
            <Col xl={16} lg={18} md={18} sm={18} xs={18}>
              <h1 style={{ color: '#ffffff' }}>Alberta Spine Foundation</h1>
            </Col>
          </Row>
          <h4 style={{ color: '#ffffff' }}>
            The objective of the Alberta Spine Foundation is to advance the delivery of
            spine care in Alberta through innovative clinical care, focused education and
            academic collaboration
          </h4>
        </Col>
        <Col xl={12} lg={12} md={24}>
          <picture>
            <source srcSet={'/images/banner.webp'} type='image/webp' />
            <img className='hero-img' src={'/images/banner.jpg'} alt='banner' />
          </picture>
        </Col>
      </Row>
    </div>
  )
}
