import React from 'react'
import { Link } from 'react-router-dom'
import Button from 'antd/lib/button'
import Result from 'antd/lib/result'

export const NotFound = () => {
  return (
    <Result
      status='404'
      title={<h1>404</h1>}
      subTitle='Oops! Nothing to see here...'
      extra={
        <Button type='primary' shape='round' size='large'>
          <Link to='/'>Take me home</Link>
        </Button>
      }
    />
  )
}
