import { isWebpSupported } from 'react-image-webp/dist/utils'

export const sections = {
  MISSION: 'mission',
  ABOUT: 'about',
  STORIES: 'stories',
  TEAM: 'team',
  CONTACT: 'contact',
  DONATE: 'donate',
  PARTNERS: 'partners',
}

export const hasWebP = isWebpSupported()

export const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent)
